@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1200px;
}
.student-app .ql-editor {
  padding: 0 !important;
}

.custom-slider .slick-dots button::before {
  font-size: 14px;
}
