@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.circle-loading{
  @apply text-center;
}
.circle-loading .loading {
  margin: 0 auto;
  height: 20px;
  width: 20px;
  border: 4px solid white;
  border-radius: 50%;
  border-right-color: #4338CA;
  border-bottom-color: #4338CA;
  -webkit-transition: all 0.5s ease-in;
  -webkit-animation-name:             rotate;
  -webkit-animation-duration:         1.0s;
  -webkit-animation-iteration-count:  infinite;
  -webkit-animation-timing-function: linear;

  transition: all 0.5s ease-in;
  animation-name:             rotate;
  animation-duration:         1.0s;
  animation-iteration-count:  infinite;
  animation-timing-function: linear;
}