.navigation-btn {
    padding: unset !important;
    height: 50px !important;
    width: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50% !important;
    position: relative;
    background: unset !important;
    border: unset !important;
    svg {
        position: absolute;
        top: 13px;
        height: 25px !important;
        width: 53px !important;
        left: 0;
        path {
            stroke: white;
        }
    }
    &:hover,
    &.ant-dropdown-open {
        background: #2062af !important;
        border: unset !important;
    }
    // &.mobile {
    //     svg {
    //         path {
    //             stroke: black;
    //         }
    //     }
    // }
}

.navigation-btn-over {
    top: 80px !important;
    width: 380px;
    height: 370px;
    overflow-y: auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #e3e3e3;
        border-radius: 10px;
    }
    &::before {
        display: none !important;
    }
    .ant-dropdown-menu-root {
        display: flex;
        background: transparent !important;
        flex-wrap: wrap;
        gap: 20px 20px !important;
        padding: 15px !important;
        flex-grow: 1;
        box-shadow: unset !important;
        .ant-dropdown-menu-item {
            width: 100px;
            height: 80px;
            text-align: center;
            display: block !important;
            border-radius: 8px;
            .navigation-item {
                transition: all 0.1s !important;
                div {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            &:hover {
                color: white;
                background: #2062af !important;
            }
        }
    }
    img {
        width: 100%;
        height: 40px;
        object-fit: contain;
    }
}
