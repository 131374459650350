.explore-detail-page {
    padding: 40px;
    h1 {
        color: #00ffff;
        font-size: 28px;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 10px;
    }
    .meta-stats {
        color: white;
    }
    p {
        line-height: 1.5;
        text-align: justify;
    }
    padding: 40px 0;
    .wrapper-list-explore {
        display: grid;
        grid-gap: 20px;
        margin-top: 30px;
        .explore-item {
            .explore-item-content {
                background-color: #080446;
            }
            h5 {
                color: white;
                font-weight: bold;
                font-size: 18px;
            }
            a {
                margin-top: 12px;
                margin-bottom: 12px;
                display: inline-block;
                color: #00ffff;
            }
            p {
                color: white;
                font-size: 13px;
            }
        }
    }

    .search-input {
        width: 336px;
        height: 36px;
    }
    .wrapper-structure-items {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -16px;
        .structure-item {
            margin-top: 32px;
            padding: 16px;
            width: 33.33%;
            @media (max-width: 768px) {
                width: 100%;
            }
            .structure-item-image {
                position: relative;
                width: 100%;
            }
            .background-image {
                position: absolute;
                top: -7px;
                left: -7px;
            }
            .image {
                // position:absolute;
                // top: 0;
                // left: 0;
                width: 352px;
                height: 242px;
                -webkit-clip-path: polygon(20% 0%, 80% 0%, 100% 0%, 100% 80%, 80% 100%, 0% 100%, 0% 86%, 0% 20%);
                clip-path: polygon(6% 0%, 31% 0%, 100% 0%, 100% 90%, 94% 100%, 0% 100%, 0% 94%, 0% 11%);
            }
            .meta-stats {
                color: white;
                position: absolute;
                bottom: 16px;
                width: 100%;
                padding: 0 32px;
            }
            .structure-item-text {
                text-align: center;
                color: #00ffff;
                font-size: 18px;
                text-transform: uppercase;
                font-weight: bold;
                margin-top: 12px;
                // font-family: 'Aquire', sans-serif;
            }
        }
        .wrapper-ribbon {
            position: absolute;
            top: 4px;
            right: 16px;
        }
    }
}
.explore-detail-card-list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-items:center;
    gap: 1%;
    .explore-detail-card {
        cursor: pointer;
        margin-top: 25px;
        color: white;
        width: 24%;
        min-height: 200px;
        .thumb img {
            height: 200px;
            width: 100%;
            object-fit: fill;
        }
        .detail {
            padding: 14px 8px;
            background: #080446;
            color: white;
        }
        @media screen and (max-width: 1280px) {
            width: 100%;
        }
    }
}

.text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
