.setting-page {
    @media (max-width: 768px) {
        .wrapper {
            display: block;
        }
        .sidebar-wrapper {
            width: 100% !important;
        }
        .sidebar-setting-menu {
            width: 100%;
        }
        .content-wrapper {
            width: 100%;
            margin-bottom: unset !important;
        }
        .control {
            display: block;
        }
        .control-btn {
            margin: 20px 0px;
        }
    }
}
