@import 'variables';
.table-customize {
  .sort-icon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    &.active{
      color: $color-theme;
    }
  }
}
