.auth-form {
  background: rgba(8, 4, 70, 0.84);
  width: 540px;

  @media (max-width: 600px) {
    width: 90%;
    margin: 0 auto;
  }
}
.auth-input {
  @apply block rounded-lg py-3 px-3 mb-5 w-full outline-none;
  background-color: #d9d9d9;
}
.error {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  height: 40px;
  display: flex;
  align-items: center;
}

.social-login-text {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #fdfdfe;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
