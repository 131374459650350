@import "_variables";

.app-container {
    margin-top: 110px !important;

    @media (max-width: 768px) {
        margin-top: 80px !important;
    }
}
.bg-theme {
    background-color: $bg-theme;
}
.color-theme {
    color: $color-theme;
}

.text-primary {
    color: #080446;
}

.text-secondary {
    color: #4e99bc;
}

.bg-purple {
    background-color: #6700ff;
}

.text-purple {
    color: #6700ff;
}

.btn-primary {
    background-color: #4e99bc;
    color: #080446;
}

.btn-secondary {
    background-color: #080446;
    color: #4e99bc;
    border: 1px solid #4e99bc;
    border-radius: 2.25rem;
    padding: 0.25rem 1rem;
}

.bg-secondary {
    background-color: #4e99bc !important;
}

.bg-primary {
    background-color: #080446;
}

.bg-primary-light {
    background: rgba(8, 4, 70, 0.7);
}

.border-secondary {
    border-color: #4e99bc;
}

.bg-hero {
    background-image: url("/modules/home/assets/images/bg-hero.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-design {
    background-image: url("/modules/home/assets/images/meta-design.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.h-560 {
    height: 35rem;
}

// custom antd

.ant-rate-star-zero .ant-rate-star-first,
.ant-rate-star-zero .ant-rate-star-second {
    color: rgba(78, 153, 188, 0.51);
}

// custom class

.shine {
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.shine:after {
    content: "";
    position: absolute;
    top: -50%;
    left: -60%;
    width: 20%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);

    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.13) 0%,
        rgba(255, 255, 255, 0.13) 77%,
        rgba(255, 255, 255, 0.5) 92%,
        rgba(255, 255, 255, 0) 100%
    );
}

/* Hover state - trigger effect */
.shine:hover:after {
    opacity: 1;
    left: 130%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
}

/* Active state */
.shine:active:after {
    opacity: 0;
}

.home-hero {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.bg-header-scroll {
    background-color: #080446;
    height: 110px;

    @media (max-width: 768px) {
        height: 80px;
    }
}

.home-hero__heading {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.home-hero__search {
    position: relative;
}

.home-hero__search input {
    &::placeholder {
        font-size: 18px;
        color: rgba(255, 255, 255, 0.5);

        @media (max-width: 1024px) {
            font-size: 14px;
        }
    }
}

.home-categories {
    margin-top: 35rem;
    width: 100%;

    @media (max-width: 768px) {
        overflow-x: scroll;
    }
}

.setting-input {
    @apply block rounded-lg py-2 px-3 w-full text-black outline-none;
    background-color: #d9d9d9;
}

.meta-item {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.meta-list {
    max-width: 1200px;
}

.meta-cover {
    height: 175px;
    width: 100%;
    object-fit: cover;
    transform: translateY(-5px);

    @media (max-width: 768px) {
        height: 120px;
    }
}

.meta-title {
    // min-height: 48px;
    font-weight: 500;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.meta-subtitle {
    // min-height: 24px;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.meta-stats {
    color: #6700ff;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    @media (max-width: 768px) {
        font-size: 10px;
    }
}

.meta-design {
    height: 620px;
}

.meta-support-links a {
    color: #fff !important;
}

.meta-rating {
    font-size: 18px;

    @media (max-width: 768px) {
        font-size: 12px;
    }
}

.meta-details {
    // margin-top: 110px;

    @media (max-width: 768px) {
        margin-top: 80px;
    }
}

.image-gallery-image,
.image-gallery-slide.center,
.image-gallery-slides,
.image-gallery-thumbnail-inner,
.image-gallery-thumbnail-image,
.image-gallery-thumbnail,
.image-gallery-thumbnail.active {
    border-radius: 8px !important;
    border-width: 0px !important;
}

.image-gallery-thumbnail {
    margin-bottom: 10px;
}
.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    // background-image: url("../images/background_footer.jpg");
    background-color: #19216f;
    // background-color: #080446;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 300px;
    width: 100%;
    @media screen and (max-width: 1280px) {
        .footer-custom {
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
        }
    }
}
