@import "./modules/common/assets/css/style.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@font-face {
  font-family: 'Aquire';
  src: local('Aquire'), url(./modules/base/assets/Aquire-BW0ox.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@import url('https://fonts.cdnfonts.com/css/aquire');
.avatar-dropdown-position {
  @apply z-10 mx-3 absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200;
  top: 60px;
}
$width-menu-left: 102px;
.app {
  display: flex;
  overflow: hidden;
  height: 100vh;
  .app-left {
    width: $width-menu-left;
    overflow-y: auto;
    z-index: 9999;
  }
  .app-right {
    width: calc(100% - #{$width-menu-left});
    background-color: white;
    overflow-y: auto;
    height: 100%;
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #e3e3e3;
      border-radius: 10px;
    }
  }
  .app-content {
    margin-top: 84px;
  }
  header {
    position: fixed;
    top: 0;
    width: calc(100% - #{$width-menu-left});
    max-width: 100vw;
    z-index: 99;
    height: 84px;
  }

  .bg-header {
    background: $bg-theme-darken;
  }
}
.sidebar-menu {
  background-color: $bg-theme-darken;
  max-height: 100vh;
  overflow-y: auto;
  height: 100%;
  min-height: 100vh;
  padding-top: 20px;
  .sidebar-menu-item {
    display: block;
    text-align: center;
    padding: 12px 0;
    a {
      display: inline-block;
      color: white;
    }
    .sidebar-menu-item-icon {
      font-size: 32px;
    }
    .sidebar-menu-item-text {
      text-align: center;
      font-size: 12px;
    }
    &.active {
      a {
        color: #00ffff;
      }
    }
  }
}

.ribbon {
  line-height: 28px;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  letter-spacing: -1px;
  display: block;
  width: 48px;
  height: 32px;
  background: #fabb05;
  color: white;
  position: relative;
  text-align: center;

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-right: 24px solid transparent;
    border-left: 24px solid transparent;
    border-top: 12px solid #fabb05;
    position: absolute;
    top: 32px;
    left: 0;
  }
}

.container-iframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

.text-aquire{
  font-family: 'Aquire', sans-serif!important;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 35px;
  color: #00ffff
}

.image-gallery {
  width: 100%;
  height: auto;
  border: #fefefe solid 1px;
  border-radius: 10px;
}

.image-gallery-slide img {
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.text-description{
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
}
.section-page{
  background-color: #101a5f;
}

@media only screen and (max-width: 600px) {
  .app {
    .app-left {
      display: none;
    }

    .app-right {
      width: 100%;
    }
    .app-content {
      margin-top: 84px;
    }
    header {
      width: 100%;
    }
  }
}


