.custom-menu-item {
    //   padding: unset !important;
    height: unset !important;
    margin-bottom: 0px !important;
    &:nth-child(1) {
        margin-top: unset !important;
    }
    img {
        height: 30px;
        width: 30px;
    }
}

.content {
    display: flex;
    align-items: center;
    div {
        margin-left: 12px;
        font-size: 12px;
        transform: translateY(-2px);
    }
}
.ant-drawer-content {
    height: 100%;
    overflow-y: scroll;
}
.ant-drawer-body {
    padding: 0px !important;
}

.mobile-menu-drawer {
    .ant-drawer-content-wrapper {
        height: 100vh;
        .ant-menu-root {
            height: 100vh;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 10px;
            }
            /* Track */
            &::-webkit-scrollbar-track {
                border-radius: 10px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #e3e3e3;
                border-radius: 10px;
            }
        }
        // .ant-drawer-content {
        //     height: 100%;
        //     overflow-y: scroll;
        // }
    }
}

.user-email {
    position: relative;
    p {
        width: 100%;
        height: 50px;
        position: absolute;
        top: 50%;
    }
}
