.page-loading {
  position: fixed;
  top: 0;
  left: 220px;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .page-loading-backdrop {
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    opacity: 0.4;
    z-index: 1;
  }
  .circle-loading {
    opacity: 1;
    z-index: 1;
    position: absolute;
    top: 50%;
    z-index: 9;
  }
}
